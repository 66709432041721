import store from "@/store";

export default {
    mounted(el, bindings) {
        let [name, method] = bindings.value;
        let userPermission = store.getters.permissions;
        if (!userPermission[name]) {
            el.parentNode && el.parentNode.removeChild(el);
            return;
        }
        if (userPermission[name].indexOf(method) === -1) {
            el.parentNode && el.parentNode.removeChild(el);
        }
    }
}