import './plugins/axios'
import {createApp} from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import ElementPlus from 'element-plus'
// import zhCn from 'element-plus/dist/locale/zh-cn.mjs'
import './font-style/font.css'
import 'element-plus/dist/index.css'
import {installAxios} from "@/plugins/axios";
import directives from "@/directives"
import * as components from '@element-plus/icons-vue'

const app = createApp(App)
installAxios(app)
for (const key in components) {
    const componentConfig = components[key];
    app.component(componentConfig.name, componentConfig);
}
app.use(store).use(router).use(ElementPlus).use(directives).mount('#app')
// 国际化
// app.use(store).use(router).use(ElementPlus, {locale:zhCn}).mount('#app')
