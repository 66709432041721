import {createStore} from 'vuex'

export default createStore({
    state: {
        name: localStorage.getItem('name') || "",
        id: localStorage.getItem('id') || "",
        token: localStorage.getItem('token') || "",
        auth_id: localStorage.getItem('auth_id') || "",
        // 后台
        routers: localStorage.getItem("routers") || "",
        menus: localStorage.getItem("menus") || "",
        permissions: localStorage.getItem("permissions") || "",
    },
    getters: {
        routers(state) {
            if (state.routers) {
                return JSON.parse(state.routers);
            }
        },
        menus(state) {
            if (state.menus) {
                return JSON.parse(state.menus);
            }
        },
        permissions(state) {
            if (state.permissions) {
                return JSON.parse(state.permissions);
            }
        }
    },
    mutations: {
        login(state, {name, token, id, auth_id, routers, menus, permissions}) {
            state.name = name;
            state.token = token;
            state.id = id;
            state.auth_id = auth_id;
            localStorage.setItem('name', name);
            localStorage.setItem('token', token);
            localStorage.setItem('id', id);
            localStorage.setItem('auth_id', auth_id);

            state.routers = JSON.stringify(routers);
            localStorage.setItem('routers', state.routers);

            state.menus = JSON.stringify(menus);
            localStorage.setItem('menus', state.menus);

            state.permissions = JSON.stringify(permissions);
            localStorage.setItem('permissions', state.permissions);
        },
        logout(state) {
            state.name = "";
            state.token = "";
            state.id = "";
            state.auth_id = "";
            // 清除localStorage
            localStorage.removeItem('name');
            localStorage.removeItem('token');
            localStorage.removeItem('id');
            localStorage.removeItem('auth_id');
            state.routers = "";
            localStorage.removeItem('routers');

            state.menus = "";
            localStorage.removeItem('menus');

            state.permissions = "";
            localStorage.removeItem('permissions');
        },
        saveAuthId(state, auth_id) {
            state.auth_id = auth_id;
            localStorage.setItem('auth_id', auth_id);
        },
    },
    actions: {},
    modules: {},
})
