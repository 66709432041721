<script setup>
import {useStore} from "vuex";
import {computed} from "vue";
import {useRouter} from "vue-router";
import {ArrowDown} from '@element-plus/icons-vue';

const store = useStore();
const name = computed(() => store.state.name)
const router = useRouter();

function doLogout() {
  store.commit("logout");
  router.push({name: "Login"})
}

function goBasic() {
  router.push({name: "Basic"})
}

</script>

<template>
  <el-container>
    <el-header height="72px" style="border-bottom: 1px solid #f5f5f5;">
      <div class="pg-header">
        <div class="logo">
          <router-link :to="{name:'Home'}">
            <img src="./assets/logo.png">
          </router-link>
        </div>
        <el-dropdown v-if="name" class="top-menu">
          <span class="el-dropdown-link">
          {{ name }}
          <el-icon class="el-icon--right">
            <arrow-down/>
          </el-icon>
          </span>
          <template #dropdown>
            <el-dropdown-menu>
              <el-dropdown-item @click="goBasic">我的主页</el-dropdown-item>
              <el-dropdown-item @click="doLogout">退出登录</el-dropdown-item>
            </el-dropdown-menu>
          </template>
        </el-dropdown>
        <!--        <div v-if="name" @click="doLogout" class="top-menu">{{ name }}</div>-->
        <div v-else class="top-menu">
          <router-link :to="{name: 'Login'}">
            <el-button>登录</el-button>
          </router-link>
          |
          <router-link :to="{name: 'Register'}">
            <el-button>注册</el-button>
          </router-link>
        </div>
      </div>
    </el-header>
      <router-view></router-view>
  </el-container>
</template>

<style>
body {
  margin: 0;
  font-family:"JetBrainsMono-Regular";
}

.pg-header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;

  height: 72px;
}

.pg-header img {
  height: 100%;
}

.pg-header .logo {
  height: 48px;

}

.pg-header .top-menu a {
  padding: 0 5px;
  text-decoration: none;
}

.example-showcase .el-dropdown-link {
  cursor: pointer;
  color: var(--el-color-primary);
  display: flex;
  align-items: center;
}
</style>
