import {createRouter, createWebHistory} from 'vue-router'


const routes = [
    {
        // 登录
        path: '/login',
        name: 'Login',
        component: () => import('../views/LoginView.vue'),
    },
    {
        // 注册
        path: '/register',
        name: 'Register',
        component: () => import('../views/RegisterView.vue'),
    },
    {
        // 首页
        path: '/front',
        name: 'Front',
        component: () => import('../views/FrontView.vue'),
        children: [
            {
                // 基本信息
                path: '',
                name: 'Home',
                component: () => import('../views/account/HomeView.vue'),
            },
            {
                // 基本信息
                path: 'basic',
                name: 'Basic',
                component: () => import('../views/account/BasicView.vue'),
            },
            {
                // 实名认证
                path: 'auth',
                name: 'Auth',
                component: () => import('../views/account/AuthView.vue'),
            },
            {
                // 实名认证
                path: 'auth/edit',
                name: 'AuthEdit',
                component: () => import('../views/account/AuthEditView.vue'),
            },
            {
                // 实名认证
                path: 'wallet',
                name: 'Wallet',
                component: () => import('../views/account/WalletView.vue'),
            },
            {
                // 发布运单
                path: 'pub',
                name: 'Pub',
                component: () => import('../views/order/PubView.vue'),
            },
            {
                // 实名认证
                path: '/pub/list',
                name: 'PubList',
                component: () => import('../views/order/PubListView.vue'),
            },
        ]
    },
    {
        // 后台登录
        path: '/backLogin',
        name: 'BackLogin',
        component: () => import('../views/BackLoginView.vue'),
    },
    {
        path: '/backFront',
        name: 'BackFront',
        component: () => import('../views/BackFrontView.vue'),
        children: [
            {
                path: 'menu',
                name: 'menu',
                component: () => import('../views/rbac/MenuView.vue'),
            },
            {
                path: 'role',
                name: 'role',
                component: () => import('../views/rbac/RoleView.vue'),
            },
            {
                path: 'user',
                name: 'user',
                component: () => import('../views/rbac/UserView.vue'),
            },
            {
                path: 'shipper',
                name: 'shipper',
                component: () => import('../views/backend/ShipperView.vue'),
            },
            {
                path: 'finance',
                name: 'finance',
                component: () => import('../views/backend/FinanceView.vue'),
            },
        ]
    },
    {
        path: '',
        redirect: {name: 'Login'},
    },
    {
        // 不匹配上述路由，显示404 NOT FOUND
        path: '/:path(.*)',
        component: () => import('../views/NotFoundView.vue'),
    },
]

const router = createRouter({
    history: createWebHistory(),
    routes
})


router.beforeEach((to, from, next) => {
    // to，即将访问路由对象
    // from，当前正要离开路由
    // next() 继续向后执行，去to的页面
    // next(false) 不跳转，还在当前页面。
    // next("/xxx")  next({name:"xxx"})  next({pat:"/xxx"})
    let token = localStorage.getItem("token");
    if (token) {
        // 已登录，可以向目标地址访问
        next();
        return
    }
    // 未登录，登录页面
    if (to.name === "Login" || to.name === "Register" || to.name === "BackLogin") {
        next();
        return;
    }
    // 未登录，访问的其他地址
    next({name: "Login"});
})

export default router
